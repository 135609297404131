<template>
  <div class="relative">
    <!---Title-->
    <div class="title">
      <h1>Listado de envíos</h1>
      <div class="toolbar">
        <!---Export-->
        <button @click="exportToExcel" class="btn btn--outline">Exportar</button>
        <!---End--->
        <!--Filter by date-->
        <div class="space-x-4">
          <span
            @click="currentDateFilter = 'today'"
            class="text-xs px-4 py-2 rounded cursor-pointer"
            :class="{
            'bg-secondary text-white border-none' : currentDateFilter === 'today',
            'text-gray-900 border border-solid border-gray-900' : currentDateFilter!=='today'}">Pedidos que se entregan hoy</span>
          <span
            @click="currentDateFilter = 'tomorrow'"
            class="text-xs px-4 py-2 rounded cursor-pointer"
            :class="{
            'bg-secondary text-white border-none' : currentDateFilter === 'tomorrow',
            'text-gray-900 border border-solid border-gray-900' : currentDateFilter!=='tomorrow'}">Pedidos que se entregan mañana</span>
        </div>
        <!--End-->
        <!---Search-->
        <div @focusout="showSearch= false" @click="showSearch= true" class="search">
          <svg class="search__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
               fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
          <input id="search"
                 name="search"
                 @focusout="showSearch= false"
                 v-model="search"
                 class="input"
                 :class="{'show' : showSearch}" type="text"
                 placeholder="Buscar por nombre">
        </div>
        <!--End-->
      </div>
    </div>
    <!---End-->
    <!--Table-->
    <div class="max-w-full overflow-auto">
      <!--Header-->
      <div class="table">
        <div class="table__header">
          <div><span>Fecha de recojo</span></div>
          <div><span>Fecha de entrega</span></div>
          <div><span>Tipo de servicio</span></div>
          <div><span>Local de recojo</span></div>
          <div><span>Dirección</span></div>
          <div><span>Referencia</span></div>
          <div><span>Distrito</span></div>
          <div><span>Nombre de cliente</span></div>
          <div><span>Teléfono</span></div>
          <div><span>Costo de envío</span></div>
          <div><span>Producto</span></div>
          <div><span>Cantidad</span></div>
          <div><span>Total a cobrar</span></div>
          <div><span>Notas de entrega</span></div>
          <div><span>Ubicación</span></div>
        </div>
        <div class="table__content">
          <div v-for="(o, index) in filteredList"
               :key="index"
               class="row"
               :class="{'row--error' : o.error}">
            <div class="row__item">
              <span class="name">FECHA DE RECOJO</span>
              <input v-model="o.pickUpDate" class="input" type="date" id="pickUpDate" name="pickUpDate">
            </div>
            <div class="row__item">
              <span class="name">FECHA DE ENTREGA</span>
              <input v-model="o.deadline" class="input" type="date" id="deadline" name="deadline">
            </div>
            <div class="row__item">
              <span class="name">TIPO DE SERVICIO</span>
              <select v-model="o.type" class="select" name="type" id="type">
                <option
                  v-for="(e, index) in [  { value: 'scheduled', price: 0,label: 'Programado'} ,  { value: 'flex', price: 2.10,label: 'Flex'}]"
                  :value="e" :key="index">{{ e.label }}
                </option>
              </select>
            </div>
            <div class="row__item">
              <span class="name">LOCAL DE RECOJO</span>
              <input v-model="o.pickupAddress" class="input" type="text" id="pickupAddress" name="pickupAddress">
            </div>
            <div class="row__item">
              <span class="name">DIRECCIÓN</span>
              <input v-model="o.address" class="input" type="text" id="address" name="address">
            </div>
            <div class="row__item">
              <span class="name">REFERENCIA</span>
              <input v-model="o.reference" class="input" type="text" id="reference" name="reference">
            </div>
            <div class="row__item">
              <span class="name">DISTRITO</span>
              <select v-model="o.district" class="select" name="district" id="district">
                <option v-for="(e, index) in districts" :value="e" :key="index">{{ e.label }}</option>
              </select>
            </div>
            <div class="row__item">
              <span class="name">CLIENTE</span>
              <input v-model="o.fullName" class="input" type="text" id="fullName" name="fullName">
            </div>
            <div class="row__item">
              <span class="name">CELULAR</span>
              <input v-model="o.phone" class="input" type="text" id="phone" name="phone">
            </div>
            <div class="row__item">
              <span class="name">COSTO DE ENVÍO</span>
              <input :value="o.district.price + o.type.price" class="input" type="text" disabled id="price"
                     name="price">
            </div>
            <div class="row__item">
              <span class="name">PRODUCTO</span>
              <input v-model="o.product" class="input" type="text" id="product" name="product">
            </div>
            <div class="row__item">
              <span class="name">CANTIDAD</span>
              <input v-model="o.quantity" class="input" type="number" id="quantity" name="quantity">
            </div>
            <div class="row__item">
              <span class="name">TOTAL A COBRAR</span>
              <input v-model="o.totalToBeCollected" class="input" type="number" id="totalToBeCollected"
                     name="totalToBeCollected">
            </div>
            <div class="row__item">
              <span class="name">NOTAS DE ENTREGA</span>
              <input v-model="o.notes" class="input" type="text" id="notes"
                     name="notes">
            </div>
            <div class="row__item">
              <span class="name">UBICACIÓN</span>
              <input v-model="o.location" class="input" type="text" id="location"
                     name="location">
            </div>
          </div>
        </div>
      </div>
      <!--End-->
    </div>
    <!---End--->

    <!--Filter-->
    <div class="filter">
      <div class="filter__trigger" @click="showFilter= !showFilter">
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Filtrar</title>
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                d="M32 144h448M112 256h288M208 368h96"/>
        </svg>
      </div>
      <div id="filterContent" class="filter__content" :class="{'block opacity-100': showFilter}">
        <!--Deadline-->
        <div class="form-group mb-4">
          <label for="deadline">Filtrar por fecha</label>
          <v-date-picker
            id="deadline"
            name="deadline"
            v-model="filterData.deadline"
            :dot="true"
            color="red"
            is-expanded
            is-range
            :timezone="`America/Lima`"
          />
        </div>
        <button @click="filter('dates')" class="btn">Filtrar</button>
      </div>
    </div>
    <!--End Filter-->
  </div>
</template>

<script>
import { db, Timestamp } from '@/firebase/firebaseConfig'
import moment from 'moment'

export default {
  name: 'ShipmentsIndex',
  data () {
    return {
      list: [],
      progress: false,
      showSearch: false,
      showFilter: false,
      filterData: {
        deadline: {
          start: new Date(),
          end: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      },
      search: '',
      districts: [
        {
          label: 'PUEBLO LIBRE',
          price: 7.9
        },
        {
          label: 'EL AGUSTINO',
          price: 7.9
        },
        {
          label: 'LA VICTORIA',
          price: 7.9
        },
        {
          label: 'CERCADO DE LIMA',
          price: 7.9
        },
        {
          label: 'RIMAC',
          price: 7.9
        },
        {
          label: 'BREÑA',
          price: 7.9
        },
        {
          label: 'MAGDALENA',
          price: 7.9
        },
        {
          label: 'SAN MIGUEL',
          price: 7.9
        },
        {
          label: 'SAN LUIS',
          price: 7.9
        },
        {
          label: 'SAN ISIDRO',
          price: 7.9
        },
        {
          label: 'SAN BORJA',
          price: 7.9
        },
        {
          label: 'MIRAFLORES',
          price: 7.9
        },
        {
          label: 'JESUS MARIA',
          price: 7.9
        },
        {
          label: 'LA MOLINA',
          price: 7.9
        },
        {
          label: 'SANTA ANITA',
          price: 7.9
        },
        {
          label: 'SMP',
          price: 7.9
        },
        {
          label: 'SAN MARTIN DE PORRES',
          price: 7.9
        },
        {
          label: 'INDEPENDENCIA',
          price: 7.9
        },
        {
          label: 'LOS OLIVOS',
          price: 7.9
        },
        {
          label: 'COMAS',
          price: 7.9
        },
        {
          label: 'CALLAO',
          price: 7.9
        },
        {
          label: 'SANTIAGO DE SURCO',
          price: 7.9
        },
        {
          label: 'SURCO',
          price: 7.9
        },
        {
          label: 'SURQUILLO',
          price: 7.9
        },
        {
          label: 'BARRANCO',
          price: 7.9
        },
        {
          label: 'CHORRILLOS',
          price: 7.9
        },
        {
          label: 'SAN JUAN DE MIRAFLORES',
          price: 7.9
        },
        {
          label: 'SJM',
          price: 7.9
        },
        {
          label: 'SAN JUAN DE LURIGANCHO',
          price: 7.9
        },
        {
          label: 'SJL',
          price: 7.9
        },
        {
          label: 'ATE',
          price: 7.9
        },
        {
          label: 'CHORRILLOS 2',
          price: 11.9
        },
        {
          label: 'VILLA EL SALVADOR',
          price: 11.9
        },
        {
          label: 'VES',
          price: 11.9
        },
        {
          label: 'VILLA MARIA DEL TRIUNFO',
          price: 11.9
        },
        {
          label: 'VMT',
          price: 11.9
        },
        {
          label: 'LA MOLINA 2',
          price: 11.9
        },
        {
          label: 'ATE 2',
          price: 11.9
        },
        {
          label: 'HUACHIPA',
          price: 11.9
        },
        {
          label: 'SAN JUAN DE LURIGANCHO 2',
          price: 11.9
        },
        {
          label: 'SJL 2',
          price: 11.9
        },
        {
          label: 'COLLIQUE',
          price: 11.9
        },
        {
          label: 'CARABAYLLO',
          price: 11.9
        },
        {
          label: 'PUENTE PIEDRA',
          price: 11.9
        },
        {
          label: 'SAN MARTIN DE PORRES 2',
          price: 11.9
        },
        {
          label: 'SMP 2',
          price: 11.9
        },
        {
          label: 'CALLAO 2',
          price: 11.9
        },
        {
          label: 'LA PUNTA',
          price: 11.9
        },
        {
          label: 'LA MOLINA 3',
          price: 14.9
        },
        {
          label: 'LURIGANCHO CHOSICA',
          price: 14.9
        },
        {
          label: 'HUAYCAN',
          price: 14.9
        },
        {
          label: 'JICAMARCA',
          price: 14.9
        },
        {
          label: 'CARABAYLLO 2',
          price: 14.9
        },
        {
          label: 'PUENTE PIEDRA 2',
          price: 14.9
        },
        {
          label: 'VENTANILLA',
          price: 14.9
        },
        {
          label: 'VILLA EL SALVADOR 2',
          price: 17.9
        },
        {
          label: 'VILLA MARIA DEL TRIUNFO 2',
          price: 17.9
        },
        {
          label: 'VENTANILLA 2',
          price: 19.9
        },
        {
          label: 'MI PERU',
          price: 19.9
        },
        {
          label: 'PUENTE PIEDRA 3',
          price: 19.9
        },
        {
          label: 'MARQUEZ',
          price: 19.9
        },
        {
          label: 'HUAYCAN 2',
          price: 19.9
        },
        {
          label: 'VENTANILLA 3',
          price: 24.9
        },
        {
          label: 'PACHACUTEC',
          price: 24.9
        },
        {
          label: 'PUENTE PIEDRA 4',
          price: 24.9
        },
        {
          label: 'CARABAYLLO 3',
          price: 24.9
        },
        {
          label: 'LURIGANCHO CHOSICA 2',
          price: 24.9
        },
        {
          label: 'CHACLACAYO',
          price: 24.9
        }
      ],
      currentDateFilter: 'today'
    }
  },
  computed: {
    filteredList () {
      return this.list.filter(o => {
        return o.fullName.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    user () {
      return this.$store.state.user
    }
  },
  watch: {
    currentDateFilter (value) {
      this.filter(value)
    }
  },
  async created () {
    try {
      await this.$store.dispatch('setUser') // Set in store
      await this.filter('today')
    } catch (e) {
      this.$toast.error('Error al cargar datos', {
        duration: 6000
      })
    }
  },
  methods: {
    /**
     * Filter
     * @param type
     * @returns {Promise<void>}
     */
    async filter (type) {
      try {
        this.showFilter = false
        this.list = []
        this.$store.dispatch('showProgress')
        // Set start date
        let startDate = new Date()
        let endDate = new Date()
        startDate.setHours(0)
        startDate.setMinutes(0)
        startDate.setSeconds(0)
        // Set end date
        endDate.setHours(23)
        endDate.setMinutes(59)
        endDate.setSeconds(0)

        let startTimes = null
        let endTimes = null

        if (type === 'today') {
          startTimes = Timestamp.fromDate(startDate)
          endTimes = Timestamp.fromDate(endDate)
        } else if (type === 'tomorrow') {
          startDate.setDate((new Date()).getDate() + 1)
          endDate.setDate((new Date()).getDate() + 1)
          startTimes = Timestamp.fromDate(startDate)
          endTimes = Timestamp.fromDate(endDate)
        } else if (type === 'dates') {
          startDate = new Date(this.filterData.deadline.start)
          startDate.setHours(0)
          startDate.setMinutes(0)
          startDate.setSeconds(0)
          console.log(startDate)
          endDate = new Date(this.filterData.deadline.end)
          endDate.setHours(23)
          endDate.setMinutes(59)
          endDate.setSeconds(0)
          console.log(endDate)

          startTimes = Timestamp.fromDate(startDate)
          endTimes = Timestamp.fromDate(endDate)
        }

        const querySnapshot = await db.collection('detail')
          .where('clientId', '==', this.user.client.id)
          .where('deadline', '>=', startTimes)
          .where('deadline', '<=', endTimes)
          .orderBy('deadline', 'asc')
          .get()

        querySnapshot.forEach((doc) => {
          const obj = {
            id: doc.id,
            ...doc.data(),
            pickUpDate: doc.data().pickUpDate.toDate(),
            deadline: doc.data().deadline.toDate()
          }

          obj.pickUpDate = moment(obj.pickUpDate).format('YYYY-MM-DD')
          obj.deadline = moment(obj.deadline).format('YYYY-MM-DD')

          this.list.push({
            ...obj
          })
        })
      } catch (e) {
        console.log(e)
        this.$toast.error('Error al filtrar', {
          duration: 6000
        })
      } finally {
        this.$store.dispatch('hideProgress')
      }
    },
    /**
     * Exports to excel
     */
    async exportToExcel () {
      try {
        const _ = require('lodash')
        const list = _.cloneDeep(this.list)
        list.forEach(o => {
          o.type = o.type.label
          o.district = o.district.label
          o.pickupAddress = o.pickupAddress.address
        })
        import('@/vendor/Export2Excel').then(excel => {
          const headerVal = ['pickUpDate', 'deadline', 'type', 'pickupAddress', 'address', 'reference', 'district', 'fullName', 'phone', 'product', 'quantity', 'totalToBeCollected', 'notes', 'location']
          const headerTitle = ['Fecha de recojo', 'Fecha de entrega', 'Tipo de servicio', 'Local de recojo', 'Dirección', 'Referencia', 'Distrito', 'Nombre de cliente', 'Teléfono', 'Producto', 'Cantidad', 'Total a cobrar', 'Notas de entrega', 'Ubicación']
          const data = this.formatJson(headerVal, list)
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: 'Envíos Enwam',
            autoWidth: true,
            bookType: 'xlsx'
          })
        })
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * Format json
     * @param filterVal
     * @param jsonData
     * @returns {*}
     */
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>
